import React, {useContext} from "react";
import {Typography, Space, Col, Row} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {isIOS} from "react-device-detect";
// images
import bee from "../../../../../../../../images/social_bee.svg";
import vk from "../../../../../../../../images/social_vk.svg";
import ok from "../../../../../../../../images/social_ok.svg";
import wa from "../../../../../../../../images/social_wa_c.svg";
import tg from "../../../../../../../../images/social_tg_c.svg";
import vb from "../../../../../../../../images/social_vb_c.svg";
import sk from "../../../../../../../../images/social_sk_c.svg";
import yt from "../../../../../../../../images/social_yt_c.svg";
import ya from "../../../../../../../../images/social_ya.svg";
import tt from "../../../../../../../../images/social_tt.svg";
import nav from "../../../../../../../../images/social_nav_c.svg";
import gmap from "../../../../../../../../images/social_gmap_c.svg";
import yamap from "../../../../../../../../images/social_yamap_c.svg";
import gmail from "../../../../../../../../images/social_gmail_c.svg";
import mail from "../../../../../../../../images/social_mail.svg";
import sp from "../../../../../../../../images/social_sp.svg";
import go from "../../../../../../../../images/social_go.svg";
import kin from "../../../../../../../../images/social_kin.svg";
import gis from "../../../../../../../../images/social_gis_c.svg";
import bzv from "../../../../../../../../images/social_bz_v.svg";
import infinity_bl from "../../../../../../../../images/infinity_bl.svg";
import {LayoutContext} from "../../../../../../../../core/Contexts";
interface ISocial {
    name: string;
    icons: string[];
    iconsWidth?: number;
    nameMobile: string;
}

export const SOCIAL_SERVICES: ISocial[] = [
    {
        name: "Мессенджеры",
        icons: [tg, wa, sk, vb],
        nameMobile: "Мессенджеры"
    },
    {
        name: "сервисы Яндекса",
        icons: [kin, go, ya, sp],
        nameMobile: "сервисы Яндекса"
    },
    {
        name: "Соц сети",
        icons: [vk, ok],
        nameMobile: "Соц сети"
    },
    {
        name: "Карты",
        icons: [yamap, gis, gmap, nav],
        nameMobile: "Карты"
    },
    {
        name: "Почту",
        icons: [mail, gmail, ya],
        nameMobile: "Почту"
    },
    // {
    //     name: "Музыку",
    //     icons: [vk, ya],
    //     nameMobile: "Музыку"
    // },
    {
        name: "Видео",
        icons: [yt, tt],
        nameMobile: "Видео"
    },
    {
        name: "Безлимит",
        icons: [bzv],
        nameMobile: "Безлимит"
    },
    {
        name: "Билайн",
        icons: [bee],
        // iconsWidth: 14,
        nameMobile: "Билайн"
    }
];

const Item: React.FC<IProps> = observer(
    ({phone, name, connection_price, packet_internet, packet_minutes, sms, region, group_name}) => {
        const {t} = useTranslation();
        const contextLayout = useContext(LayoutContext);
        const infinityLogo =
            _.gte(contextLayout?.width, 767) ?? 0 ? (
                <img src={infinity_bl} width={35} height={45} alt="logo" />
            ) : (
                <img src={infinity_bl} width={35} height={16} alt="logo" />
            );

        return (
            <Typography as="div" className="colapse-conteiner">
                <Typography as="div" className={"name-tariff"}>
                    {t(name)}
                </Typography>
                <Typography as="div" className="container">
                    <Typography as="div" className="info">
                        <Typography as="div" className="info-tariff">
                            <Space direction="vertical">
                                <Typography as="div" className="group-icons">
                                    <Space direction="vertical">
                                        {SOCIAL_SERVICES.map((item, index) => {
                                            if (
                                                item.name === "Видео" &&
                                                _.lte(connection_price, 1100) &&
                                                _.isEqual(group_name, "Безлимит")
                                            ) {
                                                return null;
                                            }
                                            if (
                                                item.name === "Карты" &&
                                                _.lte(connection_price, 1100) &&
                                                _.isEqual(group_name, "Безлимит")
                                            ) {
                                                return null;
                                            }
                                            if (
                                                item.name === "Почту" &&
                                                _.lte(connection_price, 1100) &&
                                                _.isEqual(group_name, "Безлимит")
                                            ) {
                                                return null;
                                            }
                                            if (
                                                (item.name === "сервисы Яндекса" &&
                                                    _.gt(connection_price, 1100) &&
                                                    _.isEqual(group_name, "Безлимит")) ||
                                                (item.name === "сервисы Яндекса" &&
                                                    !_.isEqual(group_name, "Безлимит"))
                                            ) {
                                                return null;
                                            }
                                            if (
                                                item.name === "Видео" &&
                                                _.lte(connection_price, 1100)
                                            ) {
                                                return null;
                                            }
                                            if (
                                                item.name === "сервисы Яндекса" &&
                                                _.gt(connection_price, 1100)
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <Row className="colapse-conteiner--social-icons-row">
                                                    <Col className="social-icons-col colapse-conteiner--social-icons-col">
                                                        {item.icons.map((icon) => (
                                                            <img
                                                                className="social-icon"
                                                                src={
                                                                    _.isEqual(
                                                                        SOCIAL_SERVICES.length - 1,
                                                                        index
                                                                    )
                                                                        ? icon
                                                                        : icon
                                                                }
                                                                width={item.iconsWidth ?? 16}
                                                                alt="logo"
                                                            />
                                                        ))}
                                                    </Col>
                                                    <Typography className="social-text colapse-conteiner--social-text">
                                                        {t(`Безлимит на ${item.name}`)}
                                                    </Typography>
                                                </Row>
                                            );
                                        })}
                                    </Space>
                                </Typography>

                                <Typography
                                    as="div"
                                    className={isIOS ? "price-ios-rest" : "price-rest"}
                                >
                                    {t("На все остальное")}
                                </Typography>
                                <Space
                                    className="bl-info-common-wrapper"
                                    direction="horizontal"
                                    size={_.gte(contextLayout?.width, 767) ? 12 : 12}
                                >
                                    <Col
                                        className={`col-info-packet ${
                                            _.isEqual(group_name, "Безлимит")
                                                ? "bg-value-int-gold"
                                                : ""
                                        } `}
                                    >
                                        <Typography as="div" className="info-packet-value">
                                            <Typography
                                                className={
                                                    _.isEqual(group_name, "Безлимит")
                                                        ? "dark"
                                                        : "gold"
                                                }
                                            >
                                                {packet_internet !== 0
                                                    ? packet_internet
                                                    : infinityLogo}
                                            </Typography>
                                            <span className="suffix inline">{" ГБ"}</span>
                                        </Typography>
                                    </Col>
                                    <Col className="col-info-packet">
                                        <Typography className="info-packet-value " as="div">
                                            <Typography className="gold">
                                                {packet_minutes !== 0
                                                    ? packet_minutes
                                                    : infinityLogo}
                                            </Typography>

                                            <span className="suffix inline">{" мин"}</span>
                                        </Typography>
                                    </Col>

                                    <Col className="col-info-packet">
                                        <Typography className="info-packet-value " as="div">
                                            <Typography className="gold">
                                                {sms !== 0 ? sms : infinityLogo}
                                            </Typography>

                                            <span className="suffix inline">{" sms"}</span>
                                        </Typography>
                                    </Col>
                                </Space>
                                <Typography
                                    as="div"
                                    className={isIOS ? "price-ios" : "price-android"}
                                >
                                    {t("Абонентская плата {connection_price} ₽", {
                                        connection_price
                                    })}
                                </Typography>
                            </Space>
                        </Typography>
                    </Typography>
                </Typography>
            </Typography>
        );
    }
);

interface IProps {
    phone: number;
    name: string;
    connection_price: number;
    packet_internet: number;
    packet_minutes: number;
    sms: number;
    region: string | undefined;
    group_name: string;
}

export default Item;
