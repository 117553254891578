import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
//images
import bee from "../../../../images/social_bee.svg";
import vk from "../../../../images/social_vk.svg";
import ok from "../../../../images/social_ok.svg";
import wa from "../../../../images/social_wa_c.svg";
import tg from "../../../../images/social_tg_c.svg";
import vb from "../../../../images/social_vb_c.svg";
import sk from "../../../../images/social_sk_c.svg";
import yt from "../../../../images/social_yt_c.svg";
import ya from "../../../../images/social_ya.svg";
import tt from "../../../../images/social_tt.svg";
import nav from "../../../../images/social_nav_c.svg";
import gmap from "../../../../images/social_gmap_c.svg";
import yamap from "../../../../images/social_yamap_c.svg";
import gmail from "../../../../images/social_gmail_c.svg";
import mail from "../../../../images/social_mail.svg";
import sp from "../../../../images/social_sp.svg";
import go from "../../../../images/social_go.svg";
import kin from "../../../../images/social_kin.svg";
import gis from "../../../../images/social_gis_c.svg";
import bzv from "../../../../images/social_bz_v.svg";

interface ISocial {
    name: string;
    icons: string[];
    iconsWidth?: number;
}

export const SOCIAL_SERVICES: ISocial[] = [
    {
        name: "Мессенджеры",
        icons: [tg, wa, sk, vb]
    },
    {
        name: "сервисы Яндекса",
        icons: [kin, go, ya, sp]
    },
    {
        name: "Соц сети",
        icons: [vk, ok]
    },
    {
        name: "Карты",
        icons: [yamap, gis, gmap, nav]
    },
    {
        name: "Почту",
        icons: [mail, gmail, ya]
    },
    // {
    //     name: "Музыку",
    //     icons: [vk, ya],
    //     nameMobile: "Музыку"
    // },
    {
        name: "Видео",
        icons: [yt, tt]
    },
    {
        name: "Безлимит",
        icons: [bzv]
    },
    {
        name: "Билайн",
        icons: [bee]
        // iconsWidth: 14,
    }
];

export default observer(function SocialIconsServices({
    isMain = false,
    isPageTarrif = false,
    isPagePlan = false,
    isPagePlans = false,
    socialArrayNew = false,
    subscriptionFee,
    group_name
}: TSocialIconsServices) {
    const {t} = useTranslation();
    const SOCIAL_SERVICES_NEW: ISocial[] = [];

    function getSocialServices() {
        if (socialArrayNew) {
            JSON.parse(JSON.stringify(SOCIAL_SERVICES)).forEach((obj: ISocial, index: number) => {
                if (obj.name === "Социальные сети") {
                    obj.name = "Соц сети";
                    SOCIAL_SERVICES_NEW.push(obj);
                } else if (obj.name === "Почтовые сервисы") {
                    obj.name = "Почту";
                    SOCIAL_SERVICES_NEW.push(obj);
                } else {
                    SOCIAL_SERVICES_NEW.push(obj);
                }
            });
            return SOCIAL_SERVICES_NEW;
        } else {
            return SOCIAL_SERVICES;
        }
    }

    return (
        <>
            <div
                className={`ant-collapse-content-box-main
                ${isPagePlan && "ant-collapse-content-box-plan"}
                ${isPagePlans && "ant-collapse-content-box-plans"}
                `}
            >
                {getSocialServices().map((item, index) => {
                    if (
                        item.name === "Видео" &&
                        _.lte(subscriptionFee, 1100) &&
                        _.isEqual(group_name, "Безлимит")
                    ) {
                        return null;
                    }
                    if (
                        item.name === "Карты" &&
                        _.lte(subscriptionFee, 1100) &&
                        _.isEqual(group_name, "Безлимит")
                    ) {
                        return null;
                    }
                    if (
                        item.name === "Почту" &&
                        _.lte(subscriptionFee, 1100) &&
                        _.isEqual(group_name, "Безлимит")
                    ) {
                        return null;
                    }
                    if (
                        (item.name === "сервисы Яндекса" &&
                            _.gt(subscriptionFee, 1100) &&
                            _.isEqual(group_name, "Безлимит")) ||
                        (item.name === "сервисы Яндекса" && !_.isEqual(group_name, "Безлимит"))
                    ) {
                        return null;
                    }
                    if (item.name === "Видео" && _.lte(subscriptionFee, 1100)) {
                        return null;
                    }
                    if (item.name === "сервисы Яндекса" && _.gt(subscriptionFee, 1100)) {
                        return null;
                    }

                    return (
                        <>
                            <Row
                                className={`social-icons-row ${
                                    isPageTarrif && "social-icons-row-tariff"
                                } `}
                            >
                                <Col className="social-icons-col ">
                                    {item.icons.map((icon) => (
                                        <img
                                            className="social-icon"
                                            src={
                                                _.isEqual(SOCIAL_SERVICES.length - 1, index)
                                                    ? icon
                                                    : icon
                                            }
                                            alt="logo"
                                        />
                                    ))}
                                </Col>
                                <Typography className="social-text">
                                    {t(`Безлимит на ${item.name}`)}
                                </Typography>
                            </Row>
                        </>
                    );
                })}
            </div>
            {isPagePlans && _.lte(subscriptionFee, 1100) && (
                <Typography as="div" className="subscription-fee-590-margin" />
            )}
        </>
    );
});

type TSocialIconsServices = {
    isMain?: boolean;
    isPageTarrif?: boolean;
    isPagePlan?: boolean;
    isPagePlans?: boolean;
    socialArrayNew?: boolean;
    subscriptionFee?: number;
    group_name?: string;
};
