import {Col, Row, Typography, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import bee from "../../../../../../../../images/social_bee.svg";
import vk from "../../../../../../../../images/social_vk.svg";
import ok from "../../../../../../../../images/social_ok.svg";
import wa from "../../../../../../../../images/social_wa_c.svg";
import tg from "../../../../../../../../images/social_tg_c.svg";
import vb from "../../../../../../../../images/social_vb_c.svg";
import sk from "../../../../../../../../images/social_sk_c.svg";
import yt from "../../../../../../../../images/social_yt_c.svg";
import ya from "../../../../../../../../images/social_ya.svg";
import tt from "../../../../../../../../images/social_tt.svg";
import nav from "../../../../../../../../images/social_nav_c.svg";
import gmap from "../../../../../../../../images/social_gmap_c.svg";
import yamap from "../../../../../../../../images/social_yamap_c.svg";
import gmail from "../../../../../../../../images/social_gmail_c.svg";
import mail from "../../../../../../../../images/social_mail.svg";
import sp from "../../../../../../../../images/social_sp.svg";
import go from "../../../../../../../../images/social_go.svg";
import kin from "../../../../../../../../images/social_kin.svg";
import gis from "../../../../../../../../images/social_gis_c.svg";
import bzv from "../../../../../../../../images/social_bz_v.svg";

export default function Mobile({price, group_name, sms, minutes, internet}: IProps) {
    const {t} = useTranslation();
    return (
        <Row className="row-list-service">
            <Col span={24} className="col-list-service-desctop">
                <Space direction="vertical" size={8}>
                    <Row className="social-icons-row">
                        <Col span={7}>
                            {" "}
                            <img src={wa} width={14} alt="logo" className="social-icon" />
                            <img src={tg} width={14} alt="logo" className="social-icon" />
                            <img src={vb} width={14} alt="logo" className="social-icon" />
                            <img src={sk} width={14} alt="logo" className="social-icon" />
                        </Col>
                        <Col span={17}>
                            {" "}
                            <Typography as="div" className="social-icons-text">
                                {t("Безлимит на Мессенджеры")}
                            </Typography>
                        </Col>
                    </Row>
                    {!_.isEqual(group_name, "Безлимит") ? (
                        <>
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={vk} width={14} alt="logo" className="social-icon" />
                                    <img src={ok} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Соц сети")}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    {" "}
                                    <img
                                        src={yamap}
                                        width={14}
                                        alt="logo"
                                        className="social-icon"
                                    />
                                    <img src={gis} width={14} alt="logo" className="social-icon" />
                                    <img src={gmap} width={14} alt="logo" className="social-icon" />
                                    <img src={nav} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Карты")}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={mail} width={14} alt="logo" className="social-icon" />
                                    <img
                                        src={gmail}
                                        width={14}
                                        alt="logo"
                                        className="social-icon"
                                    />

                                    <img src={ya} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Почту")}
                                    </Typography>
                                </Col>
                            </Row>
                            {_.lte(price, 590) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        {" "}
                                        <img
                                            src={yt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={tt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Видео")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={bzv} width={16} alt="logo" className="social-icon " />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Безлимит")}
                                    </Typography>
                                </Col>
                            </Row>

                            <Row className="social-icons-row">
                                <Col span={7}>
                                    {" "}
                                    <img src={bee} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Билайн")}
                                    </Typography>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            {_.gt(price, 1100) ? (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={vk}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ok}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Соц сети")}
                                        </Typography>
                                    </Col>
                                </Row>
                            ) : null}

                            {_.gt(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={kin}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={go}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ya}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={sp}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на сервисы Яндекса")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            {_.lte(price, 1100) ? (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={vk}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ok}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Соц сети")}
                                        </Typography>
                                    </Col>
                                </Row>
                            ) : null}
                            {_.lte(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        {" "}
                                        <img
                                            src={yamap}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={gis}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={nav}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={gmap}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Карты")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            {_.lte(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        <img
                                            src={gmail}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={mail}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={ya}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Почту")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}
                            {_.lte(price, 1100) ? null : (
                                <Row className="social-icons-row">
                                    <Col span={7}>
                                        {" "}
                                        <img
                                            src={yt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                        <img
                                            src={tt}
                                            width={14}
                                            alt="logo"
                                            className="social-icon"
                                        />
                                    </Col>
                                    <Col span={17}>
                                        {" "}
                                        <Typography as="div" className="social-icons-text">
                                            {t("Безлимит на Видео")}
                                        </Typography>
                                    </Col>
                                </Row>
                            )}

                            <Row className="social-icons-row">
                                <Col span={7}>
                                    <img src={bzv} width={16} alt="logo" className="social-icon " />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Безлимит")}
                                    </Typography>
                                </Col>
                            </Row>

                            <Row className="social-icons-row">
                                <Col span={7}>
                                    {" "}
                                    <img src={bee} width={14} alt="logo" className="social-icon" />
                                </Col>
                                <Col span={17}>
                                    {" "}
                                    <Typography as="div" className="social-icons-text">
                                        {t("Безлимит на Билайн")}
                                    </Typography>
                                </Col>
                            </Row>
                        </>
                    )}
                </Space>
            </Col>
        </Row>
    );
}

interface IProps {
    price: number;
    group_name: string;
    sms: number;
    minutes: number;
    internet: number;
}
